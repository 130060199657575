import React from 'react'
import Slider from 'react-slick'
// import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

import { RichText, Elements } from 'prismic-reactjs'
import { Box } from 'rebass'

// -- Function to add unique key to props
const propsWithUniqueKey = function (props, key) {
    return Object.assign(props || {}, { key })
}

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
const htmlSerializer = function (type, element, content, children, key) {
    var props = {}
    //return null
    switch (type) {
        case Elements.embed: // Embed
            props = Object.assign(
                {
                    'data-oembed': element.oembed.embed_url,
                    'data-oembed-type': element.oembed.type,
                    'data-oembed-provider': element.oembed.provider_name,
                },
                element.label ? { className: element.label } : {}
            )
            const embedHtml = React.createElement('div', {
                dangerouslySetInnerHTML: {
                    __html: `<div class="video-container"><iframe  width="560" height="315" src="https://www.youtube.com/embed/${
                        element.oembed.embed_url.split('?v=')[1]
                    }?rel=0&playsinline=1&origin=https://tallerlazum.com&modestbranding=1" title="Lazum video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`,
                },
            })
            return React.createElement('div', propsWithUniqueKey(props, key), embedHtml)
        // Return null to stick with the default behavior
        default:
            return null
    }
}

export default ({ data }) => (
    <Layout>
        <article className='sheet'>
            <div className='sheet__inner'>
                <h1 className='sheet__title'>{data.prismicProject.data.title.text}</h1>
                <p className='sheet__lead'>{data.prismicProject.data.subtitle.text}</p>

                <div style={{ paddingBottom: '1rem' }} className='sheet__slider'>
                    <Slider infinite={true} autoplay={true} dots={true} slidesToShow={1} arrows={true} autoplaySpeed={7000} speed={500}>
                        <figure>
                            <Img fluid={data.prismicProject.data.image1.fluid} />
                            <figcaption>
                                <h6 className='card__title'>{data.prismicProject.data.caption1.text}</h6>
                            </figcaption>
                        </figure>
                        <figure>
                            <Img fluid={data.prismicProject.data.image2.fluid} />
                            <figcaption>
                                <h6 className='card__title'>{data.prismicProject.data.caption2.text}</h6>
                            </figcaption>
                        </figure>
                        <figure>
                            <Img fluid={data.prismicProject.data.image3.fluid} />
                            <figcaption>
                                <h6 className='card__title'>{data.prismicProject.data.caption3.text}</h6>
                            </figcaption>
                        </figure>
                        <figure>
                            <Img fluid={data.prismicProject.data.image4.fluid} />
                            <figcaption>
                                <h6 className='card__title'>{data.prismicProject.data.caption_4.text}</h6>
                            </figcaption>
                        </figure>
                        <figure>
                            <Img fluid={data.prismicProject.data.image5.fluid} />
                            <figcaption>
                                <h6 className='card__title'>{data.prismicProject.data.caption_5.text}</h6>
                            </figcaption>
                        </figure>
                        <figure>
                            <Img fluid={data.prismicProject.data.image6.fluid} />
                            <figcaption>
                                <h6 className='card__title'>{data.prismicProject.data.caption_6.text}</h6>
                            </figcaption>
                        </figure>
                    </Slider>
                    <div style={{ paddingTop: '3rem' }} className='sheet__body'>
                        <RichText render={data.prismicProject.data.description.raw} htmlSerializer={htmlSerializer} />
                    </div>
                </div>
            </div>

            {/* <div className='sheet__body' dangerouslySetInnerHTML={{ __html: data.prismicProject.data.description.html }} /> */}
        </article>
    </Layout>
)

export const query = graphql`
    query ProjectQuery($slug: String!, $lang: String!) {
        prismicProject(uid: { eq: $slug }, lang: { eq: $lang }) {
            uid
            data {
                title {
                    text
                }
                subtitle {
                    text
                }
                image1 {
                    fluid(maxWidth: 1000, maxHeight: 800) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                image2 {
                    fluid(maxWidth: 1000, maxHeight: 800) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                image3 {
                    fluid(maxWidth: 1000, maxHeight: 800) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                image4 {
                    fluid(maxWidth: 1000, maxHeight: 800) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                image5 {
                    fluid(maxWidth: 1000, maxHeight: 800) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                image6 {
                    fluid(maxWidth: 1000, maxHeight: 800) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                caption1 {
                    text
                }
                caption2 {
                    text
                }
                caption3 {
                    text
                }
                caption_4 {
                    text
                }
                caption_5 {
                    text
                }
                caption_6 {
                    text
                }
                description {
                    raw
                    html
                }
            }
        }
    }
`
